<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, onMounted, watch } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";
import LoadingPage from "../../components/loading.vue";
import MethodService from "../../service/MethodService";
import Data from "./dataFormMenu";
import LanguaService from "@/service/LanguageService";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const targetDomainsSelect = Data.targetDomains;
const rolesSelect = Data.roles;
const targetTypeSelectByPrimary = Data.targetTypeByPrimary;
const targetTypeSelectByProbe = Data.targetTypeByProbe;
const loadingBtn = ref(false);
const validateFlag = ref(false);
const router = useRoute();
const routerPush = useRouter();
const srcIcon = ref(null);
const listMenuCha = ref([]);
const listDichVu = ref([]);
const listUsecase = ref([]);
const listDynamic = ref([]);
const listTableScreen = ref([]);
const listLanguage = reactive({
  value: [],
});
const notDefineLanguages = ref([
  {
    code: "en",
    value: "",
  },
  {
    code: "vi",
    value: "",
  },
]);
const selectedToAddLang = ref("");
const loadingPage = ref(false);
const listIconMenu = ref([]);
const countClick = ref(0);
const isDynamic = ref(false);
const isDisableMenuCha = ref(false);
const dataLinkParam = reactive({
  value: [
    {
      code: undefined,
      value: undefined,
      action: "add",
    },
  ],
});
const errorCode = ref([]);
const errorValue = ref([]);
const errorCodeRegex = ref([]);
const errorCodeDuplicate = ref([]);
const errorCodeDefault = ref([]);
const listDefaultParam = [
  "id",
  "kpi_id",
  "kpi_code",
  "kpi_value",
  "start_date",
  "stop_date",
  "vlan",
  "search_key",
  "limit",
  "offset",
  "filters",
  "fields",
  "sort",
  "showFormSearch",
  "quality_levels",
  "uc_type",
  "page",
  "menu",
  "access_token",
  "pid",
  "urlPrimary",
  "nameProbe",
  "type_login",
];
const errorChooseLang = ref(true);
import Validate from "@/service/ValidService";
import NOICON from "@/assets/icon/none-icon.svg";
const rulesForm = reactive({
  label: [Validate.required, Validate.checkSpaceString],
  label_lang: {
    vi: [Validate.required],
    en: [Validate.required],
  },
  visible: [Validate.required],
  target_domain: [Validate.requiredChange],
  target_type: [Validate.requiredChange],
  target_id: [Validate.requiredChange],
  roles: [Validate.requiredChange],
  domains: [Validate.requiredChange],
  link: [Validate.checkNoSpace, Validate.requiredChange],
});

const checkValidCode = (code, re, index) => {
  if (code && re.test(code)) {
    errorCode.value[index] = undefined;
    errorCodeRegex.value[index] = undefined;
  } else if (code && !re.test(code)) {
    errorCode.value[index] = undefined;
    errorCodeRegex.value[index] = index;
  } else {
    errorCodeRegex.value[index] = undefined;
    errorCode.value[index] = index;
  }
};

const checkValueCode = (code, index) => {
  const listCode = dataLinkParam.value
    .filter((item, count) => item.code && count !== index)
    .map((item) => item.code);
  if (listCode.includes(code)) {
    errorCodeDuplicate.value[index] = index;
  } else {
    errorCodeDuplicate.value[index] = undefined;
  }

  if (listDefaultParam.includes(code)) {
    errorCodeDefault.value[index] = index;
  } else {
    errorCodeDefault.value[index] = undefined;
  }
};

const changeParam = (code, value, index, label) => {
  if (label === "code") {
    checkValueCode(code, index);
    const re = /^([a-zA-Z0-9_]+)$/;
    checkValidCode(code, re, index);
  }
  if (label === "value") {
    dataLinkParam.value[index].value =
      value && value.trim().length > 0 ? value : undefined;
    errorValue.value[index] = dataLinkParam.value[index].value
      ? undefined
      : index;
  }
};

const addParam = (row, index) => {
  const re = /^([a-zA-Z0-9_]+)$/;
  const listCode = dataLinkParam.value
    .filter((item, count) => item.code && count !== index)
    .map((item) => item.code);
  if (
    row.code &&
    row.value &&
    row.value.trim().length > 0 &&
    re.test(row.code) &&
    !listCode.includes(row.code) &&
    !listDefaultParam.includes(row.code)
  ) {
    const length = dataLinkParam.value.length;
    dataLinkParam.value[length - 1] = {
      code: row.code,
      value: row.value,
      action: "",
    };
    dataLinkParam.value.push({
      code: undefined,
      value: undefined,
      action: "add",
    });
  } else {
    checkValueCode(row.code, index);
    checkValidCode(row.code, re, index);
    errorValue.value[index] =
      row.value && row.value.trim().length > 0 ? undefined : index;
  }
};

const deleteParam = (index) => {
  dataLinkParam.value.splice(index, 1);
  errorCode.value[index] = undefined;
  errorValue.value[index] = undefined;
  errorCodeRegex.value[index] = undefined;
  errorCodeDuplicate.value[index] = undefined;
  errorCodeDefault.value[index] = undefined;
};

const changeArrayData = (array) => {
  if (!array) return;
  let arrData = [];
  array.forEach((item) => {
    arrData.push(item);
  });
  return arrData;
};
const renderLink = async (type, id) => {
  let link = "";
  if (type === "service") link = "/" + "services/" + id;
  if (type === "table_screen") link = "/" + "table-screen/" + id;
  if (type === "usecase") {
    const response = await mushroom.usecase.findByIdAsync({
      id: id,
      fields: "code",
    });
    if (response.result)
      link = "/" + "usecases/" + response.result.code.toLowerCase() + "/" + id;
  }
  return link;
};
const getListIconMenu = async () => {
  try {
    const response = await mushroom.icon.getAllAsync();
    if (response.result) {
      response.result.forEach((item) => {
        item.srcIcon = MethodService.thumbIcon(item.file_id, 32);
      });
      listIconMenu.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const setParams = (link, params) => {
  const url = new URL(
    "http://test/" + (link[0] === "/" ? link.slice(1) : link)
  );
  if (!url.search && !url.hash && params.length > 0 && link !== "#") {
    return `${url.pathname}?${params.join("&")}`;
  } else if (url.search && url.hash && params.length > 0) {
    return url.pathname + url.search + "&" + params.join("&") + url.hash;
  } else if (url.hash && params.length > 0) {
    return url.pathname + "?" + params.join("&") + url.hash;
  } else if (url.search && params.length > 0) {
    return url.pathname + url.search + "&" + params.join("&");
  } else if (link === "#" && params.length > 0) {
    return url.pathname + "?" + params.join("&");
  } else {
    return link;
  }
};

// thêm mới
const addNewMenu = (formEl) => {
  let checkParam = false;
  const re = /^([a-zA-Z0-9_]+)$/;
  dataLinkParam.value.forEach((item) => {
    if (
      item.action === "" &&
      (!item.code || !item.value || !re.test(item.code))
    )
      checkParam = true;
  });
  const listCodeDuplicate = Array.from(
    new Set(
      dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => item.code)
    )
  );

  if (
    !formEl ||
    checkParam ||
    dataLinkParam.value.some((item) => listDefaultParam.includes(item.code)) ||
    listCodeDuplicate.length !== dataLinkParam.value.length - 1
  )
    return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    validateFlag.value = true;
    const resForIndex = await mushroom.menu.listAsync({
      sort: "-index",
      limit: 1,
    });
    const checkMultiLangEmpty = listLanguage.value.some(
      (item) => item.error === true
    );
    if (valid && !checkMultiLangEmpty) {
      const textLink = await renderLink(
        dataForm.value.target_type,
        dataForm.value.target_id
      );

      const listParam = dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => {
          return {
            code: item.code ? item.code : null,
            value: item.value ? encodeURIComponent(item.value) : null,
          };
        });
      const strParam = listParam.map((item) => `${item.code}=${item.value}`);

      const menu_object = {
        label: dataForm.value.label,
        label_lang: dataForm.value.label_lang,
        index: dataForm.value.index
          ? dataForm.value.index
          : resForIndex.result?.[0]?.index
          ? resForIndex.result[0].index + 1
          : 1,
        parent_id: dataForm.value.parent_id,
        icon_id: dataForm.value.icon_id,
        roles: changeArrayData(dataForm.value.roles),
        visible: dataForm.value.visible,
        domains: changeArrayData(dataForm.value.domains),
        not_in_domains: changeArrayData(dataForm.value.not_in_domains),
        target_domain: dataForm.value.target_domain,
        target_type: dataForm.value.target_type,
        target_id: dataForm.value.target_id,
        link:
          dataForm.value.target_type === "static" && dataForm.value.link
            ? setParams(dataForm.value.link, strParam)
            : dataForm.value.target_type === "dynamic"
            ? setParams("/", strParam)
            : setParams(textLink, strParam),
        link_parameters: listParam,
      };
      try {
        const newIdMenu = await mushroom.menu.createAsync(menu_object);
        if (newIdMenu.result) {
          if (dataForm.value.target_type === "dynamic") {
            // tạo mới dynamic_dashboard
            const newIdDynamicDashboard =
              await mushroom.dynamic_dashboard.createAsync({
                menu_id: newIdMenu.result,
              });
            if (newIdDynamicDashboard) {
              // cập nhật lại trường target_id cho menu vừa tạo
              const resUpdateMenu = await mushroom.menu.partialUpdateAsync({
                id: newIdMenu.result,
                target_id: newIdDynamicDashboard.result,
                link:
                  "/dashboard/" +
                  newIdDynamicDashboard.result +
                  (listParam.length > 0 ? `?${strParam.join("&")}` : ""),
              });
              if (resUpdateMenu.result === newIdMenu.result)
                toastr.success(t("toastr_add_success"));
            }
          } else toastr.success(t("toastr_add_success"));
        }
        routerPush.go(-1);
      } catch (error) {
        if (error.meta.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else MethodService.showError(error.code);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

// cập nhật
const updateMenu = (formEl) => {
  let checkParam = false;
  const re = /^([a-zA-Z0-9_]+)$/;
  dataLinkParam.value.forEach((item) => {
    if (
      item.action === "" &&
      (!item.code || !item.value || !re.test(item.code))
    )
      checkParam = true;
  });

  const listCodeDuplicate = Array.from(
    new Set(
      dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => item.code)
    )
  );
  validateFlag.value = true;
  if (
    !formEl ||
    checkParam ||
    dataLinkParam.value.some((item) => listDefaultParam.includes(item.code)) ||
    listCodeDuplicate.length !== dataLinkParam.value.length - 1
  )
    return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    const checkMultiLangEmpty = listLanguage.value.some(
      (item) => item.error === true
    );
    if (valid && !checkMultiLangEmpty) {
      const textLink = await renderLink(
        dataForm.value.target_type,
        dataForm.value.target_id
      );
      const listParam = dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => {
          return {
            code: item.code ? item.code : null,
            value: item.value ? encodeURIComponent(item.value) : null,
          };
        });
      const strParam = listParam.map((item) => `${item.code}=${item.value}`);

      const menu_object = {
        id: router.params.id,
        label: dataForm.value.label,
        label_lang: dataForm.value.label_lang,
        index: dataForm.value.index,
        parent_id: dataForm.value.parent_id,
        icon_id: dataForm.value.icon_id,
        roles: changeArrayData(dataForm.value.roles),
        visible: dataForm.value.visible,
        domains: changeArrayData(dataForm.value.domains),
        not_in_domains: changeArrayData(dataForm.value.not_in_domains),
        target_domain: dataForm.value.target_domain,
        target_type: dataForm.value.target_type,
        target_id: dataForm.value.target_id,
        link:
          dataForm.value.target_type === "static" && dataForm.value.link
            ? setParams(dataForm.value.link, strParam)
            : dataForm.value.target_type === "dynamic"
            ? setParams(dataForm.value.link, strParam)
            : setParams(textLink, strParam),
        link_parameters: listParam,
      };
      try {
        const newId = await mushroom.menu.partialUpdateAsync(menu_object);
        if (newId.result === router.params.id) {
          toastr.success(t("toastr_update_success"));
          routerPush.go(-1);
        }
      } catch (e) {
        MethodService.showError(e.code);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      toastr.warning(t("toastr_check_input_again"));
      return false;
    }
  });
};
const uploadIcon = async () => {
  try {
    let nameImage = document.getElementById("uploadIcon").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg" &&
        typeImage !== ".svg" &&
        typeImage !== ".ico"
      ) {
        toastr.warning(t("t_select_format_image"));
      } else {
        try {
          let res = await mushroom.$file.uploadAsync({
            file: document.getElementById("uploadIcon").files[0],
          });

          if (res.result) {
            dataForm.value.icon_id = res.result;
            srcIcon.value = MethodService.thumbIcon(res.result, 32);
          }
        } catch (e) {
          console.error(e);
          toastr.warning(t("t_upload_failed"));
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const onInputClick = (event) => {
  event.target.value = "";
};
const setIcon = (icon) => {
  srcIcon.value = icon.srcIcon;
  dataForm.value.icon_id = icon.file_id;
};
const changeTargetType = async (e) => {
  dataForm.value.target_id = undefined;
  dataForm.value.link = undefined;
  if (e === "service") await getListDichVu();
  if (e === "table_screen") await getListTableScreen();
};
const changeTargetDomain = async () => {
  dataForm.value.target_id = undefined;
  if (dataForm.value.target_type !== "dynamic")
    dataForm.value.target_type = undefined;
  await getListDichVu();
  await getListTableScreen();
};
const getListMenuCha = async () => {
  let filter = [];
  router.name === "ThemMoiMenu"
    ? (filter = ["parent_id:is_null:true"])
    : (filter = ["parent_id:is_null:true", "id!=" + router.params.id]);
  listMenuCha.value = [];
  try {
    const response = await mushroom.menu.getAllAsync({
      filters: filter,
      sort: "index",
      fields: "id,label",
    });
    if (response.result) {
      response.result.forEach((item) => {
        listMenuCha.value.push({
          value: item.id,
          label: item.label,
        });
      });
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getListDichVu = async () => {
  listDichVu.value = [];
  try {
    const responseRoles = await mushroom.$auth.meAsync();
    const response = await mushroom.service.getAllAsync({
      fields: "id,type,name",
      filters: responseRoles.result.roles.includes("Admin")
        ? ["disabled!=true", "is_menu_hidden!=true"]
        : ["is_menu_hidden!=true"],
    });
    if (response.result) {
      if (dataForm.value.target_domain === "probe") {
        listDichVu.value = response.result.filter(
          (item) => item.type === "probe"
        );
      } else if (dataForm.value.target_domain === "bras") {
        listDichVu.value = response.result.filter(
          (item) => item.type === "bras"
        );
      } else listDichVu.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListTableScreen = async () => {
  try {
    const response = await mushroom.table_screen.getAllAsync({
      fields: "id,type,name",
    });
    if (response.result) {
      if (dataForm.value.target_domain === "probe") {
        listTableScreen.value = response.result.filter(
          (item) => item.type === "probe"
        );
      } else if (dataForm.value.target_domain === "bras") {
        listTableScreen.value = response.result.filter(
          (item) => item.type === "bras"
        );
      } else listTableScreen.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListUsecase = async () => {
  try {
    const response = await mushroom.usecase.getAllAsync({
      fields: "id,name,code",
    });
    if (response.result)
      response.result.forEach((item) => {
        listUsecase.value.push({
          value: item.id,
          label: item.name,
          code: item.code,
        });
      });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListDynamic = async () => {
  try {
    const response = await mushroom.dynamic_dashboard.getAllAsync({
      fields: "id,name",
    });
    if (response.result)
      response.result.forEach((item) => {
        listDynamic.value.push({
          value: item.id,
          label: item.name,
        });
      });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getMenuInfo = async () => {
  loadingPage.value = true;
  try {
    const response = await mushroom.menu.findByIdAsync({
      id: router.params.id,
      fields:
        "id,label,label_lang,index,parent_id,roles,icon_id,visible,domains,not_in_domains,target_domain,target_type,target_id,link_parameters,link,created_time,creator_id",
    });
    if (response.result) {
      if (response.result.target_type === "dynamic") isDynamic.value = true;
      dataForm.value = response.result;
      if (dataForm.value.icon_id)
        srcIcon.value = MethodService.thumbIcon(dataForm.value.icon_id, 32);

      let listLinkParam = [];
      if (response.result.link_parameters) {
        response.result.link_parameters.forEach((item) => {
          listLinkParam.push({
            code: item.code,
            value: decodeURIComponent(item.value),
            action: "",
          });
        });
        listLinkParam.push({
          code: undefined,
          value: undefined,
          action: "add",
        });
        dataLinkParam.value = listLinkParam;
        dataForm.value.link = formatLink(dataForm.value.link);
      }
      console.log(response.result.label_lang);
      if (response.result.label_lang) {
        let langObject = response.result.label_lang;
        let languageList = Object.keys(langObject);
        languageList.forEach((langCode) => {
          if (langObject[langCode] !== "")
            listLanguage.value.push({
              code: langCode,
              value: langObject[langCode],
            });
        });
        let notEmptyLang = listLanguage.value.map((item) => item.code);
        //Lấy ra danh sách các ngôn ngữ chưa được thực hiện đa ngôn ngữ
        let tmpNotDefineLanguage = [...notDefineLanguages.value];
        notDefineLanguages.value = tmpNotDefineLanguage.filter(
          (item) => !notEmptyLang.includes(item.code)
        );
      } else {
        dataForm.value = {
          ...dataForm.value,
          label_lang: {
            vi: "",
            en: "",
          },
        };
      }
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  } finally {
    setTimeout(() => {
      loadingPage.value = false;
    }, 1000);
  }
};

const formatLink = (value) => {
  const link = new URL(
    "http://test/" + (value[0] === "/" ? value.slice(1) : value)
  );
  if (link.search.length > 0) {
    const str = new URLSearchParams(link.search);
    dataLinkParam.value.forEach((item) => {
      if (item.code) {
        str.delete(item.code);
      }
    });
    return (
      link.pathname + (str.toString() ? "?" + str.toString() : "") + link.hash
    );
  }
  return value;
};

const checkHaveChildren = async () => {
  const res = await mushroom.menu.listAsync({
    filters: ["parent_id=" + router.params.id],
    limit: 1,
  });
  if (res?.meta?.total > 0) isDisableMenuCha.value = true;
};

const addLanguageInput = () => {
  if (!selectedToAddLang.value) {
    errorChooseLang.value = false;
    return;
  }
  errorChooseLang.value = true;
  listLanguage.value.push({
    code: selectedToAddLang.value,
    value: "",
  });
  notDefineLanguages.value = notDefineLanguages.value.filter(
    (item) => item.code !== selectedToAddLang.value
  );
  selectedToAddLang.value = "";
};

const removeLanguageMean = (code) => {
  listLanguage.value = listLanguage.value.filter((item) => item.code !== code);
  notDefineLanguages.value.push({
    code: code,
    value: "",
  });
  dataForm.value.label_lang[code] = "";
};

const saveLanguageDefine = (code) => {
  dataForm.value.label_lang[code] = removeSpace(
    dataForm.value.label_lang[code]
  );
  listLanguage.value = listLanguage.value.map((item) => {
    if (item.code === code) {
      if (removeSpace(dataForm.value.label_lang[code]) === "")
        return {
          ...item,
          error: true,
        };
      return {
        ...item,
        value: removeSpace(dataForm.value.label_lang[code]),
        error: false,
      };
    }
    return item;
  });
  console.log(dataForm.value.label_lang);
};

const removeSpace = (str) => {
  return str.replace(/\s+/g, " ").trim();
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguaService.lang = LanguaService[lang.value];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  await getListIconMenu();
  await getListUsecase();
  await getListDichVu();
  await getListDynamic();
  await getListTableScreen();
  await getListMenuCha();

  if (router.name === "CapNhatMenu") {
    await getMenuInfo();
    await checkHaveChildren();
  }
});
</script>

<template>
  <Layout>
    <LoadingPage v-if="loadingPage"></LoadingPage>

    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'ThemMoiMenu'">{{ $t("t-add") }} menu</h3>
        <h3 v-else>{{ $t("t-update") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-menu"
          >
            <el-form-item :label="$t('t-name-menu')" prop="label">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.label"
                @change="dataForm.value.label = dataForm.value.label?.trim()"
                :placeholder="$t('t-placeholder-name-menu')"
              />
            </el-form-item>
            <el-form-item label="">
              <div class="multi-language d-flex">
                <div class="title">{{ $t("multiple_language") }}</div>
                <div style="flex-grow: 1">
                  <div>
                    <div
                      v-for="item in listLanguage.value"
                      :key="item.code"
                      class="laguage_item"
                    >
                      <div class="input-language-label">
                        {{ $t(item.code) }}
                      </div>
                      <div class="input-language">
                        <el-input
                          v-model="dataForm.value.label_lang[item.code]"
                          :class="item?.error ? 'error_number' : ''"
                          @input="
                            removeSpace(dataForm.value.label_lang[item.code])
                          "
                          :placeholder="
                            $t('t_place_label_lang_of_menu', {
                              msg: $t(item.code).toLowerCase(),
                            })
                          "
                          @blur="saveLanguageDefine(item.code)"
                        />
                        <div
                          v-if="item?.error"
                          class="error"
                          style="text-align: left"
                        >
                          {{ $t("valid_required") }}
                        </div>
                      </div>
                      <el-tooltip :content="$t('t-delete')" placement="top">
                        <el-button
                          type="danger"
                          plain
                          @click="removeLanguageMean(item.code)"
                          ><i class="ri-close-fill"></i
                        ></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="d-flex" v-if="!notDefineLanguages.length == 0">
                    <div class="select-language">
                      <el-form-item label="">
                        <el-select
                          v-model="selectedToAddLang"
                          style="width: 200px"
                          :placeholder="$t('t-choose-lang')"
                        >
                          <el-option
                            v-for="item in notDefineLanguages"
                            :key="item.code"
                            :value="item.code"
                            :label="$t(item.code)"
                          />
                        </el-select>
                        <div
                          v-if="!errorChooseLang"
                          class="error"
                          style="text-align: left"
                        >
                          {{ $t("t-please-choose-lang") }}
                        </div>
                      </el-form-item>
                    </div>
                    <el-button
                      type="primary"
                      plain
                      style="padding: 8px"
                      @click="addLanguageInput"
                      ><i class="ri-add-circle-line"></i
                    ></el-button>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item :label="$t('t-parent-menu')">
              <el-select
                v-model="dataForm.value.parent_id"
                clearable
                filterable
                :placeholder="$t('t-placeholder-parent-menu')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                :disabled="isDisableMenuCha"
              >
                <el-option
                  v-for="item in listMenuCha"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-row :gutter="20">
              <el-col span="6">
                <el-form-item :label="$t('t-select-icon')">
                  <div class="icon-group">
                    <img
                      v-if="srcIcon"
                      :src="srcIcon"
                      alt="icon service-health"
                      class="icon-group__menu"
                    />
                    <img v-else :src="NOICON" alt="" height="32" />
                    <div class="icon-group__select">
                      <div
                        class="icon-group__dropdown"
                        @click="countClick++"
                        v-click-outside="
                          () => {
                            countClick = 0;
                          }
                        "
                      >
                        {{ $t("t-select-icon") }}
                      </div>

                      <div
                        class="icon-group__list"
                        :class="{ show: countClick % 2 === 1 }"
                      >
                        <div v-if="listIconMenu.length > 0">
                          <div
                            v-for="item in listIconMenu"
                            :key="item.id"
                            class="icon-item"
                            @click="setIcon(item)"
                          >
                            <img :src="item.srcIcon" alt="icon" height="20" />
                          </div>
                        </div>

                        <p v-else>{{ $t("t_not_have_icon") }}</p>
                      </div>
                    </div>

                    <div>{{ $t("t-or") }}</div>
                    <label for="uploadIcon" class="icon-group__upload"
                      ><img
                        src="../../assets/icon/upload_white.svg"
                        alt="upload"
                      />
                      <span>{{ $t("t-upload") }}</span></label
                    >
                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg, .svg, .ico"
                      id="uploadIcon"
                      style="display: none"
                      @change="uploadIcon"
                      @click="onInputClick"
                    />
                  </div>
                </el-form-item>
              </el-col>
              <el-col span="6">
                <el-form-item>
                  <el-checkbox
                    v-model="dataForm.value.visible"
                    :label="$t('t-show-menu-on-list')"
                  />
                </el-form-item>
              </el-col>
              <el-col span="6">
                <el-form-item :label="$t('t-index-number')">
                  <el-input-number
                    v-model="dataForm.value.index"
                    controls-position="right"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item :label="$t('t-group-roles')" prop="roles">
              <el-select
                v-model="dataForm.value.roles"
                filterable
                multiple
                :placeholder="$t('t-place-group-roles')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in rolesSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t-domains')" prop="domains">
              <el-select
                v-model="dataForm.value.domains"
                filterable
                multiple
                clearable
                :placeholder="$t('t-placeholder-domains')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in targetDomainsSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t-no-domains')">
              <el-select
                v-model="dataForm.value.not_in_domains"
                filterable
                multiple
                clearable
                :placeholder="$t('t-placeholder-no-domains')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in targetDomainsSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t-target-domains')" prop="target_domain">
              <el-select
                v-model="dataForm.value.target_domain"
                filterable
                clearable
                :placeholder="$t('t-placeholder-target-domains')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                @change="changeTargetDomain"
              >
                <el-option
                  v-for="item in dataForm.value.target_type === 'dynamic'
                    ? targetDomainsSelect.filter(
                        (item) => item.value !== 'primary'
                      )
                    : targetDomainsSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <div v-if="dataForm.value.target_domain">
              <el-form-item
                :label="$t('t-target-type')"
                prop="target_type"
                v-if="dataForm.value.target_domain === 'primary'"
              >
                <el-select
                  v-model="dataForm.value.target_type"
                  filterable
                  :placeholder="$t('t-placeholder-target-type')"
                  style="width: 100%"
                  :no-match-text="$t('t-match-data')"
                  :no-data-text="$t('t-no-data')"
                  @change="changeTargetType"
                  :disabled="isDynamic"
                >
                  <el-option
                    v-for="item in targetTypeSelectByPrimary.filter(
                      (item) => item.value !== 'dynamic'
                    )"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('t-target-type')"
                prop="target_type"
                v-if="dataForm.value.target_domain !== 'primary'"
              >
                <el-select
                  v-model="dataForm.value.target_type"
                  filterable
                  :placeholder="$t('t-placeholder-target-type')"
                  style="width: 100%"
                  :no-match-text="$t('t-match-data')"
                  :no-data-text="$t('t-no-data')"
                  @change="changeTargetType"
                  :disabled="isDynamic"
                >
                  <el-option
                    v-for="item in !isDynamic && router.name === 'CapNhatMenu'
                      ? targetTypeSelectByProbe.filter(
                          (item) => item.value !== 'dynamic'
                        )
                      : targetTypeSelectByProbe"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('t-link')"
                v-if="dataForm.value.target_type === 'static'"
                prop="link"
              >
                <el-input
                  v-model="dataForm.value.link"
                  :placeholder="$t('t-place-link')"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('t-target')"
                prop="target_id"
                v-if="
                  dataForm.value.target_type !== 'static' &&
                  dataForm.value.target_type &&
                  dataForm.value.target_type !== 'dynamic'
                "
              >
                <el-select
                  v-if="dataForm.value.target_type === 'service'"
                  filterable
                  v-model="dataForm.value.target_id"
                  :placeholder="$t('t-select-service')"
                  :no-match-text="$t('t-match-data')"
                  :no-data-text="$t('t-no-data')"
                >
                  <el-option
                    v-for="item in listDichVu"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <el-select
                  filterable
                  v-if="dataForm.value.target_type === 'table_screen'"
                  v-model="dataForm.value.target_id"
                  :placeholder="$t('t-select-table-screen')"
                  :no-match-text="$t('t-match-data')"
                  :no-data-text="$t('t-no-data')"
                >
                  <el-option
                    v-for="item in listTableScreen"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <el-select
                  v-if="dataForm.value.target_type === 'usecase'"
                  filterable
                  v-model="dataForm.value.target_id"
                  :placeholder="$t('t-select-usecase')"
                  :no-match-text="$t('t-match-data')"
                  :no-data-text="$t('t-no-data')"
                >
                  <el-option
                    v-for="item in listUsecase"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-select
                  filterable
                  v-if="dataForm.value.target_type === 'dynamic'"
                  v-model="dataForm.value.target_id"
                  :placeholder="$t('t-select-dynamic')"
                  :no-match-text="$t('t-match-data')"
                  :no-data-text="$t('t-no-data')"
                  :disabled="isDynamic"
                >
                  <el-option
                    v-for="item in listDynamic"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>

            <el-form-item :label="$t('t-params-list')" class="nguong-mau-sac">
              <el-table :data="dataLinkParam.value">
                <el-table-column prop="code" min-width="100" align="start">
                  <template #header>
                    <div class="info-param">
                      <span>Variable</span>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="top-start"
                        popper-class="tooltip-param"
                      >
                        <template #content>
                          {{
                            $t("t_variations_conditions", {
                              list: listDefaultParam.join(`, `),
                            })
                          }}
                        </template>
                        <i class="ri-question-line me-1 align-bottom"></i>
                      </el-tooltip>
                    </div>
                  </template>
                  <template #default="scope">
                    <el-input
                      type="text"
                      v-model="scope.row.code"
                      placeholder="Variable"
                      :class="{
                        error_number:
                          errorCode[scope.$index] === scope.$index ||
                          errorCodeRegex[scope.$index] === scope.$index ||
                          errorCodeDuplicate[scope.$index] === scope.$index ||
                          errorCodeDefault[scope.$index] === scope.$index,
                      }"
                      @change="
                        changeParam(
                          scope.row.code,
                          scope.row.value,
                          scope.$index,
                          'code'
                        )
                      "
                    />
                    <span
                      v-if="errorCode[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_required") }}</span
                    >
                    <span
                      v-if="errorCodeRegex[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_checkCode") }}</span
                    >
                    <span
                      v-if="errorCodeDuplicate[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_exist_variable") }}</span
                    >
                    <span
                      v-if="errorCodeDefault[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_not_allowed_variable") }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="value"
                  label="Value"
                  min-width="100"
                  align="start"
                >
                  <template #default="scope">
                    <el-input
                      type="text"
                      v-model="scope.row.value"
                      placeholder="Value"
                      :class="{
                        error_number: errorValue[scope.$index] === scope.$index,
                      }"
                      @change="
                        changeParam(
                          scope.row.code,
                          scope.row.value,
                          scope.$index,
                          'value'
                        )
                      "
                    />
                    <span
                      v-if="errorValue[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_required") }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('t-action')"
                  align="center"
                  width="100px"
                >
                  <template #default="scope">
                    <div class="btn-group-thao-tac">
                      <el-tooltip
                        v-if="scope.row.action === 'add'"
                        :content="$t('t-add')"
                      >
                        <button
                          type="button"
                          class="btn btn-soft-info waves-effect waves-light btn-sm"
                          @click="addParam(scope.row, scope.$index)"
                        >
                          <i class="ri-add-circle-line"></i>
                        </button>
                      </el-tooltip>
                      <el-tooltip v-else :content="$t('t-delete')">
                        <button
                          type="button"
                          class="btn btn-soft-danger waves-effect waves-light btn-sm"
                          @click="deleteParam(scope.$index)"
                        >
                          <i class="ri-delete-bin-6-line"></i>
                        </button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewMenu(ruleFormRef)"
                v-if="router.name === 'ThemMoiMenu'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateMenu(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./menu.scss" />
